@font-face {
  font-family: Luxury;
  src: url("../../public/assets/Luxury.ttf");
}
* {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.app {
  background: #e3e1dc;
 
}

p {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
}
span {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  text-align: left;
}

button {
  font-family: Montserrat !important;
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 39.01px !important;
  text-align: left;
  border: 0px;
  background-color: #fff;
  padding: 15px;
}

.title {
  font-family: Luxury;
  font-size: 64px;
  font-weight: 400;
  line-height: 61.06px;
  text-align: left;
  padding: 15px;
  text-transform: uppercase;
}

.imgcard {
  width: 100%;
  padding: 0px;
  object-fit: fill;
}

.main {
  display: flex;
  padding: 50px 200px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

a {
  background: #0a2a2f;
  font-family: Luxury;
  font-size: 36px;
  font-weight: 400;
  line-height: 34.34px;
  text-align: left;
  color: #fff !important;
  text-decoration: none !important;
  padding: 10px 15px;
  border-radius: 1em;
  text-align: center;
}

.cardronq {
  background-color: #e3e1dc !important;
}
.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.carousel-indicators {
  display: none !important;
}
@media (max-width: 1024px) {
  .main {
    display: flex;
    padding: 50px 10px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .inverse {
    flex-direction: column-reverse;
  }

  .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 90% !important;
    padding: 5px;
  }

  .imgcard {
    width: 100%;
    padding: 0px;
    object-fit: fill;
  }

  .cardronq {
    background-color: #e3e1dc !important;
  }

  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 29.26px;
    text-align: left;
    /* padding: 15px; */
  }
  span {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
  }

  button {
    font-family: Montserrat !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 39.01px !important;
    text-align: left;
    border: 0px;
    background-color: #fff;
  }
  .title {
    font-family: Luxury;
    font-size: 45px;
    font-weight: 400;
    line-height: 61.06px;
    text-align: left;
    padding: 15px;
  }
}
